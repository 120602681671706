import { keyframes } from "@emotion/react";

export const fadeIn = keyframes`
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
`;

export const scaleUp = keyframes`
    from {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }

    99.99% {
        transform: translate(-50%, -50%) scale(7);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(7);
        opacity: 0.1;
    }
`;

export const rotateCentered = keyframes`
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
`;

export const searchReveal = keyframes`
    from {
        max-height: 8rem
    }

    to {
        max-height: 300px;
    }
`;
