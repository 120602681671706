import React, { useEffect, useRef } from "react";
import { has, isEmpty, reduce } from "lodash";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.palette.lightRed};
    padding: 2rem;
    margin: 2rem auto 0 auto;
    width: 95%;
    border-radius: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 98%;
    }
`;

const Title = styled.h2`
    font-size: 3.5rem;
    color: ${({ theme }) => theme.palette.red};
    margin-bottom: 2rem;
`;

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
`;

const ListItem = styled.li``;

const ErrorButton = styled.button`
    color: ${({ theme }) => theme.palette.darkRed};
    text-align: left;
    font-weight: bold;
    &:focus {
        outline: solid;
    }
`;

const NonFieldError = styled.p`
    color: #6a1921;
    font-weight: bold;
`;

const ErrorSummary = ({ errors, setFocus = () => {} }) => {
    const nonFieldRef = useRef(null);
    const fieldRef = useRef(null);

    useEffect(() => {
        if (nonFieldRef.current) {
            nonFieldRef.current.focus();
        }

        if (fieldRef.current) {
            fieldRef.current.focus();
        }
    });

    if (isEmpty(errors)) {
        return null;
    }

    if (has(errors, "non_field_error") || has(errors, "unknown")) {
        return (
            <Wrapper aria-labelledby="error-summary" role="alert">
                <Title id="error-summary">Error Summary</Title>
                <List>
                    <ListItem>
                        <NonFieldError ref={nonFieldRef} tabIndex="0">
                            {has(errors, "non_field_error")
                                ? errors["non_field_error"].message
                                : errors["unknown"].message}
                        </NonFieldError>
                    </ListItem>
                </List>
            </Wrapper>
        );
    }

    let count = 0;

    return (
        <Wrapper aria-labelledby="error-summary" role="alert">
            <Title id="error-summary">Error Summary</Title>
            <List>
                {reduce(
                    errors,
                    (summary, field, fieldName) => {
                        if (has(field, "message")) {
                            count++;
                            return [
                                ...summary,
                                <ListItem key={fieldName}>
                                    <ErrorButton
                                        type="button"
                                        tabIndex="0"
                                        ref={count === 1 ? fieldRef : null}
                                        onClick={() => setFocus(fieldName)}
                                        id={`${fieldName}-error-button`}
                                    >
                                        {field.message}
                                    </ErrorButton>
                                </ListItem>,
                            ];
                        }
                        return summary;
                    },
                    [],
                )}
            </List>
        </Wrapper>
    );
};

export default ErrorSummary;

ErrorSummary.propTypes = {
    errors: PropTypes.shape({}).isRequired,
    setFocus: PropTypes.func,
};
