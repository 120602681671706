import "@fortawesome/fontawesome-svg-core/styles.css";
import "@fontsource/source-sans-pro/300.css";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";
import "@fontsource/source-sans-pro/700.css";
import { navigate } from "gatsby";

let nextRoute = ``;

export const onPreRouteUpdate = async ({ location }) => {
    nextRoute = location.pathname;

    /**
     * Why are we hard-coding these values?
     * Please refer to ticket below for more details!
     *
     * @ticket CW-2479
     */
    if (location.pathname === "/knowledge-center/") {
        const params = new URLSearchParams(location.search);

        if (
            params.get("topics") === "powerful-perspectives-articles" ||
            params.get("topics") === "powerful-perspectives" ||
            params.get("types") === "thought-leadership"
        ) {
            await navigate("/powerful-perspectives/");
        }
    }
};

export const shouldUpdateScroll = ({ routerProps }) => {
    if (routerProps.location.state) {
        const { disableScrollUpdate } = routerProps.location.state;
        return !disableScrollUpdate;
    }
};

if (typeof window !== `undefined`) {
    window.addEventListener("unhandledrejection", function (event) {
        if (/loading chunk \d* failed./i.test(event.reason)) {
            if (nextRoute) {
                window.location.pathname = nextRoute;
            }
        }
    });

    document.onvisibilitychange = () => {
        if (
            typeof window.trackAbandonedCart === "function" &&
            document.visibilityState === "hidden"
        ) {
            window.trackAbandonedCart();
        }
    };

    if (!window.crypto) {
        window.crypto = window.msCrypto;
    }
}

export { wrapRootElement } from "./gatsby-ssr";

export { wrapPageElement } from "./gatsby-ssr";
