import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { has } from "lodash";

import Col from "~/components/layout/Col";
import ErrorBoundary from "~/components/ErrorBoundary";

import useFieldConditionalLogic from "~/hooks/useFieldConditionalLogic";

import { getFormInput } from "~/utilities/formHelpers";

const Wrapper = styled(Col)`
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 0;
`;

const FULL_WIDTH_FIELDS = ["html", "radio"];

const getFieldWidth = (selectedFieldSize, fieldType) => {
    let renderedFieldSize = 1 / 2;

    if (
        selectedFieldSize === "large" ||
        FULL_WIDTH_FIELDS.includes(fieldType)
    ) {
        renderedFieldSize = 1;
    } else if (selectedFieldSize === "small") {
        renderedFieldSize = 1 / 3;
    }

    return renderedFieldSize;
};

const FormField = forwardRef(
    (
        {
            field,
            register,
            errors,
            uniqueFormId,
            control,
            formFields,
            getValues,
        },
        ref,
    ) => {
        let conditionalLogic = null;
        let showField = true;

        if (
            has(field, "conditionalLogic") &&
            field.conditionalLogic &&
            field.conditionalLogic !== '""'
        ) {
            conditionalLogic = JSON.parse(field.conditionalLogic);
        }

        const conditionalLogicResult = useFieldConditionalLogic(
            formFields,
            conditionalLogic,
            uniqueFormId,
            control,
        );
        if (conditionalLogic && has(conditionalLogic, "actionType")) {
            showField =
                conditionalLogic.actionType === "hide"
                    ? !conditionalLogicResult
                    : conditionalLogicResult;
        }

        return (
            <ErrorBoundary>
                {showField &&
                    field.type === "hidden" &&
                    getFormInput(
                        field,
                        register,
                        errors,
                        uniqueFormId,
                        control,
                    )}
                {showField && field.type !== "hidden" && (
                    <Wrapper
                        ref={ref}
                        key={field.id}
                        md={getFieldWidth(field.size, field.type)}
                    >
                        {getFormInput(
                            field,
                            register,
                            errors,
                            uniqueFormId,
                            control,
                            getValues,
                        )}
                    </Wrapper>
                )}
            </ErrorBoundary>
        );
    },
);

FormField.propTypes = {
    field: PropTypes.shape({}).isRequired,
    register: PropTypes.func.isRequired,
    getValues: PropTypes.func,
    errors: PropTypes.shape({}).isRequired,
    uniqueFormId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    control: PropTypes.shape({}).isRequired,
    formFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FormField;
