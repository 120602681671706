import { ErrorMessage } from "@hookform/error-message";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { camelCase, isEmpty, has } from "lodash";

import UserContent from "~/components/UserContent";
import ErrorBoundary from "~/components/ErrorBoundary";

import FaIcon from "~/components/FaIcon";

import { Error, Label } from "~/components/FormElements/Input";

import {
    OptionWrapper,
    Input,
    DynamicFieldWrapper,
} from "~/components/FormElements/Checkbox";

import transition from "~/utilities/transition";
import { sanitizeAndParseHTML } from "~/utilities/helpers";
import { getLegend, getErrorMessage } from "~/utilities/formHelpers";

export const CircleIcon = styled(FaIcon)`
    ${transition({}, "opacity")};
    opacity: 0;
    font-size: 10px;
    position: absolute;
    color: ${({ theme }) => theme.palette.white};
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
`;

export const RadioLabel = styled(Label)`
    font-size: 1.8rem;
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
    display: flex;
    align-items: center;

    &:before {
        content: "";
        height: 20px;
        width: 20px;
        background-color: ${({ theme }) => theme.palette.white};
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
        margin-right: 10px;
        padding: 10px;
        border: ${({ theme }) => `1px solid ${theme.palette.grayTwo}`};
        border-radius: 50%;
        position: relative;
    }

    ${Input}:checked ~ & {
        &:before {
            border: ${({ theme }) => `1px solid ${theme.palette.grayThree}`};
            background-color: ${({ theme }) => theme.palette.grayTwo};
        }

        ${CircleIcon} {
            opacity: 1;
            transform: translate(58%, 0%);
        }
    }

    ${Input}:focus-visible + & {
        outline: ${({ theme, error }) =>
            `3px solid ${error ? theme.palette.red : theme.palette.focusBlue}`};
        outline-offset: 5px;
    }

    @supports not selector(:focus-visible) {
        ${Input}:focus + & {
            outline: ${({ theme, error }) =>
                `3px solid ${error ? theme.palette.red : theme.palette.focusBlue}`};
            outline-offset: 5px;
        }
    }
`;

export const RadioWrapper = styled(OptionWrapper)`
    margin-right: 20px;
`;

export const OptionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Legend = styled.legend`
    margin-bottom: 5px;
`;

const DynamicOptionWrapper = ({ optionsLength, children, ...props }) => {
    let OptionWrapperReturn = (
        <RadioWrapper {...props}>{children}</RadioWrapper>
    );
    return OptionWrapperReturn;
};

const Radio = ({
    label,
    name,
    register,
    labelPlacement,
    options,
    errors = null,
    required = false,
    disabled = false,
    errorMessage = "",
}) => {
    if (isEmpty(options)) {
        return null;
    }
    const optionsLength = options?.length;
    return (
        <ErrorBoundary>
            <DynamicFieldWrapper
                aria-labelledby={`${camelCase(label)}FieldLabel`}
                aria-describedby={
                    has(errors, name) ? `${name}-field-error` : null
                }
                {...{ optionsLength }}
            >
                {getLegend(labelPlacement, required, label)}
                <OptionsWrapper>
                    {options.map(({ text, value }, index) => (
                        <DynamicOptionWrapper
                            {...{ optionsLength }}
                            key={index}
                        >
                            <Input
                                {...{ name, disabled, value }}
                                {...register(name, {
                                    required: required
                                        ? getErrorMessage(errorMessage, label)
                                        : false,
                                })}
                                type="radio"
                                id={`${name}_${index}`}
                                name={name}
                            />
                            <RadioLabel
                                htmlFor={`${name}_${index}`}
                                {...{ disabled }}
                                error={errors[`${name}_${index}`]}
                            >
                                <CircleIcon {...{ disabled }} name="circle" />
                                <UserContent>
                                    {required &&
                                    labelPlacement === "hidden_label" ? (
                                        <span aria-label="required">*</span>
                                    ) : (
                                        ""
                                    )}
                                    {sanitizeAndParseHTML(text)}
                                </UserContent>
                            </RadioLabel>
                        </DynamicOptionWrapper>
                    ))}
                </OptionsWrapper>

                <ErrorMessage
                    {...{ errors, name }}
                    render={({ message }) => (
                        <Error id={`${name}-field-error`}>{message}</Error>
                    )}
                />
            </DynamicFieldWrapper>
        </ErrorBoundary>
    );
};

Radio.propTypes = {
    errors: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
};

export default Radio;
