import styled from "@emotion/styled";
import React, { useEffect, useMemo } from "react";
import { has } from "lodash";

import { useForm } from "react-hook-form";

import buttonStyles from "~/styles/buttonStyles";

import Row from "~/components/layout/Row";
import Col from "~/components/layout/Col";

import RecaptchaTermsAndConditions from "~/components/FormExtras/RecaptchaTermsAndConditions";
import FormFields from "./FormFields";

import { useFormContext } from "~/context/FormContext";
import { usePardotUserContext } from "~/context/PardotUserContext";
import useFormSubmission from "../hooks/useFormSubmission";

import {
    getPresetFormValues,
    notifyUserOfException,
} from "~/utilities/formHelpers";

const Submit = styled.input`
    ${({ theme }) => buttonStyles({ theme, color: "purple" })};
    border: 0;
    appearance: none;
`;

const HtmlForm = (props) => {
    const {
        formPresetValues,
        formPresetErrors,
        formInformation,
        uniqueFormId,
        formId,
        modalForm,
        handleModalScroll,
    } = props;

    const { userData } = usePardotUserContext();
    const { hiddenFieldValues } = useFormContext();

    const presetValues = useMemo(() => {
        const presets = {
            ...hiddenFieldValues,
            ...formPresetValues,
        };
        return getPresetFormValues(
            formInformation,
            userData,
            presets,
            uniqueFormId,
        );
    }, [
        formInformation,
        userData,
        uniqueFormId,
        formPresetValues,
        hiddenFieldValues,
    ]);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        clearErrors,
        reset,
        getValues,
        setFocus,
        setError,
    } = useForm({
        reValidateMode: "onSubmit",
        defaultValues: presetValues,
        shouldFocusError: false,
    });

    useEffect(() => {
        if (formPresetErrors) {
            reset(presetValues, { keepErrors: true });
            notifyUserOfException(formPresetErrors, uniqueFormId, setError);
        } else {
            reset(presetValues);
        }
    }, [presetValues, reset, formPresetErrors, uniqueFormId, setError]);

    const formProps = {
        ...props,
        reset,
        setError,
    };

    const { handleFormSubmission } = useFormSubmission(formProps);

    return (
        <form
            noValidate={true}
            data-form-id={formId}
            onSubmit={handleSubmit(handleFormSubmission)}
        >
            <Row>
                <FormFields
                    {...{
                        formFields: formInformation?.formFields,
                        setFocus,
                        register,
                        errors,
                        uniqueFormId,
                        control,
                        getValues,
                        modalForm,
                        handleModalScroll,
                    }}
                />
                <Col xs={1}>
                    <Submit
                        type="submit"
                        onClick={() => {
                            if (
                                has(errors, "unknown") ||
                                has(errors, "on24_error")
                            ) {
                                clearErrors(["unknown", "on24_error"]);
                            }
                        }}
                        disabled={
                            isSubmitting || has(errors, "non_field_error")
                        }
                        value={formInformation.button.text}
                    />
                </Col>
            </Row>

            <RecaptchaTermsAndConditions />
        </form>
    );
};

export default HtmlForm;
