import { ErrorMessage } from "@hookform/error-message";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { camelCase, isEmpty, has } from "lodash";

import UserContent from "~/components/UserContent";
import ErrorBoundary from "~/components/ErrorBoundary";

import FaIcon from "../FaIcon";

import { Error, Label } from "./Input";

import transition from "~/utilities/transition";
import { sanitizeAndParseHTML } from "~/utilities/helpers";
import { getLegend, getErrorMessage } from "~/utilities/formHelpers";

export const FieldWrapper = styled.fieldset``;
export const GroupedFieldWrapper = styled.ul``;

export const Input = styled.input`
    position: absolute;
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
    opacity: 0;
`;

const CheckboxContent = styled(UserContent)`
    padding-top: 0;
`;

export const OptionWrapper = styled.div`
    position: relative;
`;

export const CheckIcon = styled(FaIcon)`
    ${transition({}, "opacity")};
    opacity: 0;
    font-size: 1.4rem;
    position: absolute;
    color: ${({ theme }) => theme.palette.white};
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
`;

export const CheckboxLabel = styled(Label)`
    font-size: 1.8rem;
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
    display: flex;
    align-items: center;

    &:before {
        content: "";
        height: 20px;
        width: 20px;
        background-color: ${({ theme, disabled }) =>
            disabled ? theme.palette.grayFour : theme.palette.grayThree};
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
        margin-right: 15px;
        padding: 10px;
        border: ${({ theme, error }) =>
            error
                ? `1px solid ${theme.palette.red}`
                : `1px solid ${theme.palette.grayOne}`};
        border-radius: 2px;
        position: relative;
    }

    ${Input}:checked + & {
        &:before {
            border: ${({ theme }) => `1px solid ${theme.palette.grayThree}`};
            background-color: ${({ theme }) => theme.palette.grayOne};
        }

        ${CheckIcon} {
            opacity: 1;
            transform: translate(40%, -5%);
        }
    }

    ${Input}:focus-visible + & {
        outline: ${({ theme, error }) =>
            `3px solid ${error ? theme.palette.red : theme.palette.focusBlue}`};
        outline-offset: 5px;
    }

    @supports not selector(:focus-visible) {
        ${Input}:focus + & {
            outline: ${({ theme, error }) =>
                `3px solid ${error ? theme.palette.red : theme.palette.focusBlue}`};
            outline-offset: 5px;
        }
    }
`;

export const DynamicFieldWrapper = ({ optionsLength, children, ...props }) => {
    let fieldWrapperReturn = <FieldWrapper {...props}>{children}</FieldWrapper>;
    return fieldWrapperReturn;
};

const DynamicOptionWrapper = ({ optionsLength, children, ...props }) => {
    let OptionWrapperReturn = (
        <OptionWrapper {...props}>{children}</OptionWrapper>
    );
    return OptionWrapperReturn;
};

const Checkbox = ({
    label,
    name,
    register,
    options,
    errors = null,
    required = false,
    disabled = false,
    labelPlacement = "",
    errorMessage = "",
}) => {
    if (isEmpty(options)) {
        return null;
    }
    const optionsLength = options?.length;
    return (
        <ErrorBoundary>
            <DynamicFieldWrapper
                aria-labelledby={`${camelCase(label)}FieldLabel`}
                aria-describedby={
                    has(errors, name) ? `${name}-field-error` : null
                }
                {...{ optionsLength }}
            >
                {getLegend(labelPlacement, required, label)}
                {options.map(({ text, value }, index) => (
                    <DynamicOptionWrapper {...{ optionsLength }} key={index}>
                        <Input
                            {...{ disabled, name, value }}
                            {...register(name, {
                                required: required
                                    ? getErrorMessage(errorMessage, label)
                                    : false,
                            })}
                            type="checkbox"
                            id={`${name}_${index}`}
                        />

                        <CheckboxLabel
                            htmlFor={`${name}_${index}`}
                            {...{ disabled }}
                            error={errors[name]}
                        >
                            <CheckIcon {...{ disabled }} name="check" />
                            <CheckboxContent>
                                {required &&
                                labelPlacement === "hidden_label" ? (
                                    <span>*</span>
                                ) : (
                                    ""
                                )}
                                {sanitizeAndParseHTML(text)}
                            </CheckboxContent>
                        </CheckboxLabel>
                    </DynamicOptionWrapper>
                ))}
                <ErrorMessage
                    {...{ errors, name }}
                    render={({ message }) => (
                        <Error id={`${name}-field-error`}>{message}</Error>
                    )}
                />
            </DynamicFieldWrapper>
        </ErrorBoundary>
    );
};

Checkbox.propTypes = {
    errors: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default Checkbox;
