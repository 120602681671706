import React from "react";

import {
    camelCase,
    snakeCase,
    find,
    findIndex,
    has,
    isArray,
    isEmpty,
    isObject,
    isString,
    reduce,
    toLower,
    toString,
} from "lodash";

import styled from "@emotion/styled";

import Input from "~/components/FormElements/Input";
import Select from "~/components/FormElements/Select";
import Radio from "~/components/FormElements/Radio";
import Checkbox from "~/components/FormElements/Checkbox";
import Textarea from "~/components/FormElements/Textarea";
import UserContent from "~/components/UserContent";
import { ScreenReaderText } from "~/styles/screenReaderText";

import { removeHtml, sanitizeAndParseHTML } from "~/utilities/helpers";
import { getLocalStorageItem } from "~/utilities/localStorageHelpers";
import { fetchInline } from "~/utilities/fetchHelper";
import { upsertOption } from "~/utilities/reducer";
import {
    getLocalStorageSerialize,
    setLocalStorageSerialize,
} from "~/utilities/localStorageHelpers";

export const RECAPTCHA_LABEL = "g_recaptcha_v3_token";

export const getFieldName = (pardotField, formId, fieldId) =>
    `${pardotField}-input_${fieldId}-form_${formId}`;

export const getFormInput = (
    field,
    register,
    errors,
    uniqueFormId,
    control,
    getValues,
) => {
    const pardotFieldName = field?.pardotField ?? snakeCase(field.label);
    const name = getFieldName(pardotFieldName, uniqueFormId, field.id);

    switch (field.type) {
        case "text":
        case "email":
        case "num":
        case "date":
        case "phone":
            return (
                <Input
                    errorMessage={field.errorMessage}
                    autocompleteAttribute={field.autocompleteAttribute}
                    type={field.type}
                    required={field.isRequired}
                    label={field.label}
                    placeholder={field.placeholder}
                    formId={uniqueFormId}
                    {...{ register, errors, name }}
                />
            );
        case "hidden":
            return (
                <Input
                    type={field.type}
                    required={field.isRequired}
                    label={field.label}
                    placeholder={field.placeholder}
                    formId={uniqueFormId}
                    {...{ register, errors, name }}
                />
            );
        case "select":
            return (
                <Select
                    errorMessage={field.errorMessage}
                    autocompleteAttribute={field.autocompleteAttribute}
                    required={field.isRequired}
                    label={field.label}
                    options={JSON.parse(field.choices)}
                    {...{ register, errors, name }}
                />
            );
        case "textarea":
            return (
                <Textarea
                    errorMessage={field.errorMessage}
                    autocompleteAttribute={field.autocompleteAttribute}
                    required={field.isRequired}
                    label={field.label}
                    placeholder={field.placeholder}
                    errors={errors[field.name]}
                    {...{ register, errors, name }}
                />
            );
        case "radio":
            return (
                <Radio
                    errorMessage={field.errorMessage}
                    required={field.isRequired}
                    label={field.label}
                    options={JSON.parse(field.choices)}
                    labelPlacement={field.labelPlacement}
                    {...{ register, errors, name }}
                />
            );
        case "checkbox":
            return (
                <Checkbox
                    errorMessage={field.errorMessage}
                    required={field.isRequired}
                    label={field.label}
                    options={JSON.parse(field.choices)}
                    labelPlacement={field.labelPlacement}
                    {...{ register, errors, getValues, name }}
                />
            );
        case "html":
            return (
                <UserContent>{sanitizeAndParseHTML(field.content)}</UserContent>
            );
        case "fileupload":
            // For now, file upload fields can be ignored.
            break;
        default:
            console.error(`Field type not found : ${field.type}`);
            break;
    }
};

export const getConfirmationMessage = (confirmations) => {
    const defaultMessage = find(confirmations, { isDefault: true });

    return defaultMessage.message;
};

const isMetaKey = (key) => {
    return [
        `g_recaptcha_v3_token`,
        `vToken`,
        `runOnSubmitActions`,
        `on24Props`,
    ].includes(key);
};

const formatCheckbox = (formField, value, formattedKey) => {
    if (!isArray(value)) {
        return { [`${formattedKey}_1`]: value };
    }

    let choices = [];

    try {
        choices = JSON.parse(formField.choices);
    } catch (e) {
        console.error(`JSON Parse Error -> ${e.message}`);
    }

    return reduce(
        value,
        (acc, checkboxValue) => {
            const index = findIndex(choices, {
                value: checkboxValue,
            });

            if (index > -1) {
                const checkboxKey = `${formattedKey}_${index + 1}`;
                return {
                    ...acc,
                    [checkboxKey]: checkboxValue,
                };
            }

            return acc;
        },
        {},
    );
};

export const formatDataForSubmission = (data, formInformation) => {
    return reduce(
        data,
        (acc, value, key) => {
            const formattedKey = key.split("-")[1];

            if (isMetaKey(key) || !formattedKey) {
                return {
                    ...acc,
                    [key]: value,
                };
            }

            const formField = find(
                formInformation.formFields,
                ({ id }) => `input_${id}` === formattedKey,
            );

            if (formField?.type === "checkbox") {
                const checkboxData = formatCheckbox(
                    formField,
                    value,
                    formattedKey,
                );
                return {
                    ...acc,
                    ...checkboxData,
                };
            }

            return {
                ...acc,
                [formattedKey]: value,
            };
        },
        {},
    );
};

const filterCheckboxPardotValues = (fieldOptions, userInfo) => {
    const options = JSON.parse(fieldOptions);

    // Some fields may be multi-select, split by delimiter
    let userValues = [userInfo];

    if (isString(userInfo) && userInfo.includes("|")) {
        userValues = userInfo.split("|");
    }

    let pardotValues = null;

    // Helper
    const isOption = (option) => options.some(({ value }) => value === option);
    // Filter out any values that are not in the checkbox group options
    const filteredValues = userValues.filter((userValue) =>
        isOption(userValue),
    );

    if (filteredValues?.length) {
        pardotValues = filteredValues;
    }

    return pardotValues;
};

export const getPresetFormValues = (
    formInformation,
    userInformation,
    formPresetValues,
    uniqueFormId,
) => {
    if (!formInformation?.formFields) {
        return {};
    }

    return reduce(
        formInformation.formFields,
        (acc, item) => {
            let currentFields = acc;
            const pardotFieldName = item.pardotField ?? snakeCase(item.label);
            const fieldName = getFieldName(
                pardotFieldName,
                uniqueFormId,
                item.id,
            );

            if (has(userInformation, pardotFieldName)) {
                const userInfo = userInformation[pardotFieldName];
                // For checkbox make sure userData value exists in form field
                if (item.type === "checkbox") {
                    const fieldValue = filterCheckboxPardotValues(
                        item.choices,
                        userInfo,
                    );

                    if (isArray(fieldValue) && fieldValue.length) {
                        currentFields = upsertOption(currentFields, {
                            [fieldName]: fieldValue,
                        });
                    }
                } else if (item.type === "radio") {
                    currentFields = upsertOption(currentFields, {
                        [fieldName]: toString(userInfo),
                    });
                } else {
                    currentFields = upsertOption(currentFields, {
                        [fieldName]: userInfo,
                    });
                }
            }

            if (has(formPresetValues, pardotFieldName)) {
                currentFields = upsertOption(currentFields, {
                    [fieldName]: formPresetValues[pardotFieldName],
                });
            }

            const localPardotField = getLocalStorageItem(pardotFieldName);

            if (localPardotField) {
                currentFields = upsertOption(currentFields, {
                    [fieldName]: localPardotField,
                });
            }

            if (item.defaultValue) {
                currentFields = upsertOption(currentFields, {
                    [fieldName]: item.defaultValue,
                });
            }

            return currentFields;
        },
        {},
    );
};

const getHeaders = (isJson) => {
    if (isJson) {
        return { "Content-Type": "application/json" };
    }

    // Multipart Form Submission
    // Content Header will be set to multipart/form-data; boundary=---------------------------hash
    // The boundary directive needs to be set by the browser.  This mimics posting data with a form.
    return {};
};

const getBody = (isJson, formId, formattedData) => {
    if (isJson) {
        return JSON.stringify({
            formid: formId,
            payload: formattedData,
        });
    }

    if (typeof formattedData?.append === "function") {
        formattedData.append("id", formId);
    }

    // Multipart Form Submission
    return formattedData;
};

const tryAgain = (response, text) => {
    try {
        const start = text.indexOf("{");
        const end = text.lastIndexOf("}") + 1;
        const json = text.slice(start, end);

        return JSON.parse(json);
    } catch (e) {
        console.error(e.message);
        return { code: "unknown" };
    }
};

const tryToParseJSON = async (response) => {
    const text = await response.text();

    try {
        return JSON.parse(text);
    } catch (e) {
        if (e instanceof SyntaxError) {
            return tryAgain(response, text);
        }

        console.error(e.message);
        return { code: "unknown" };
    }
};

export async function submitFormData(
    formId,
    formattedData,
    isJson,
    gravityFormsEndpoint,
) {
    try {
        const headers = getHeaders(isJson);
        const body = getBody(isJson, formId, formattedData);
        const url = `${process.env.GATSBY_ADMIN_SITE_URL}${gravityFormsEndpoint}`;
        const fetchOptions = { method: "POST", body, headers };
        const response = await fetch(url, fetchOptions);
        return await tryToParseJSON(response);
    } catch (e) {
        console.error(e.message);
        return { code: "unknown" };
    }
}

export const updateMetaData = async (
    runBeforeSubmitActions,
    postId,
    recaptchaUpdateMeta,
) => {
    const options = {
        method: "POST",
        body: JSON.stringify({ postId, runBeforeSubmitActions }),
        headers: { "Content-Type": "application/json" },
    };

    return await fetchInline(
        `${process.env.GATSBY_ADMIN_SITE_URL}${recaptchaUpdateMeta}`,
        options,
    );
};

const FormLegend = styled.legend`
    margin-bottom: 15px;
    line-height: normal;
`;

export const getLegend = (labelPlacement, required, label) => {
    let labelElement;

    if (labelPlacement === "hidden_label") {
        labelElement = (
            <ScreenReaderText id={`${camelCase(label)}FieldLabel`}>
                {required ? ` Required ` : label}
            </ScreenReaderText>
        );
    } else {
        labelElement = (
            <FormLegend id={`${camelCase(label)}FieldLabel`}>
                {required ? <span>*</span> : ""}
                <ScreenReaderText>{` Required `}</ScreenReaderText>
                {label}
            </FormLegend>
        );
    }

    return labelElement;
};

export const getErrorMessage = (defaultMsg, label, gravityErrMsg = null) => {
    if (defaultMsg) {
        return defaultMsg;
    }

    if (gravityErrMsg && !gravityErrMsg?.includes("required")) {
        return gravityErrMsg;
    }

    return `${label} is required`;
};

export const ariaAlert = (label, error) => {
    if (isEmpty(error) || error.type === "required") {
        return label;
    }

    return `${label}. ${error.message}`;
};

export const getAriaLabelWithText = (label, text = "", error = null) => {
    const cleanText = removeHtml(text);

    if (cleanText && error) {
        const ariaLabel = ariaAlert(label, error);
        return `${ariaLabel}. ${cleanText}`;
    }

    if (cleanText) {
        return `${label}. ${cleanText}`;
    }

    return label;
};

export class FormSubmissionException {
    constructor(formSubmission, formData) {
        const exception = Object.assign(
            {
                code: "unknown",
                data: { validation_messages: {} },
                message:
                    "There was an error submitting the form. Refresh the page and try again.",
            },
            formSubmission,
        );

        if (isArray(exception.message) && !isEmpty(exception.message)) {
            this.errorMessage = exception.message[0];
        } else {
            this.errorMessage = exception.message;
        }

        this.errorCode = exception.code;

        this.gravityFormValidationMessages = exception.data.validation_messages;

        this.formData = formData;
    }
}

const getEmailDomain = (email) => {
    if (isEmpty(email) || !isString(email)) {
        return "";
    }

    const atSymbol = email.indexOf("@") + 1;
    const dot = email.lastIndexOf(".");
    return email.slice(atSymbol, dot);
};

const EMAIL_STORAGE_KEY = "ISPEmailData";

export const isInvalidISPEmail = (formId, email) => {
    const data = getLocalStorageSerialize(EMAIL_STORAGE_KEY, {});
    const domains = data[formId] || [];
    const emailDomain = getEmailDomain(email);
    const isInvalid = domains.includes(emailDomain);

    if (isInvalid) {
        return "You must use a valid organizational (company or university) email address. No free, publicly available email domains such as gmail, yahoo or qq will be accepted.";
    }

    return "";
};

const saveISPEmailData = (formId, emailDomain) => {
    const data = getLocalStorageSerialize(EMAIL_STORAGE_KEY, {});
    const domains = data[formId] || [];

    if (!domains.includes(emailDomain)) {
        domains.push(emailDomain);
        data[formId] = domains;
        setLocalStorageSerialize(EMAIL_STORAGE_KEY, data);
    }
};

const findEmail = (formData) => {
    if (!isObject(formData)) {
        return "";
    }

    const emailRegExp = /\S+@\S+\.\S+/;

    let email = "";

    for (const property in formData) {
        const value = formData[property];
        if (emailRegExp.test(value)) {
            email = value;
            break;
        }
    }

    return email;
};

export const saveInvalidEmailDomain = (errMsg, formData) => {
    const INVALID_ISP_FLAG = toLower(
        "No free, publicly available email domains",
    );

    const isInvalidEmailDomain = toLower(errMsg).includes(INVALID_ISP_FLAG);

    if (isInvalidEmailDomain) {
        const email = findEmail(formData);
        const formId = formData.formId;
        const emailDomain = getEmailDomain(email);
        if (formId && emailDomain) {
            saveISPEmailData(formId, emailDomain);
        }
    }
};

const handlePardotError = (formInformation, exception) => {
    const emailField = find(formInformation?.formFields, {
        pardotField: "email",
    });

    const errMsg = exception?.errorMessage;
    const formData = exception.formData;

    const isEmailError = toLower(errMsg).includes("email") && emailField;

    if (isEmailError) {
        const pardotField = emailField?.pardotField;
        const pardotLabel = emailField?.label;

        saveInvalidEmailDomain(errMsg, formData);

        return {
            fieldName: pardotField ?? pardotLabel,
            type: "validate",
            message: errMsg,
            field: emailField,
        };
    }

    return {
        fieldName: "unknown",
        type: "unknown",
        message: errMsg,
    };
};

const getUnknownError = () => {
    return {
        fieldName: "non_field_error",
        type: "custom",
        message:
            "There was an error submitting the form. Refresh the page and try again.",
    };
};

const handleGravityFormError = (formInformation, exception) => {
    const errMsgObj = exception.gravityFormValidationMessages;

    const errors = reduce(
        errMsgObj,
        (acc, gravityErrMsg, id) => {
            const field = find(formInformation?.formFields, {
                id: parseInt(id),
            });

            if (isEmpty(field)) {
                return acc;
            }

            const fieldName = field?.pardotField ?? field?.label;
            const defaultMsg = field.errorMessage;
            const label = field?.label;
            const message = getErrorMessage(defaultMsg, label, gravityErrMsg);

            return [
                ...acc,
                {
                    field,
                    fieldName,
                    type: "validate",
                    message,
                },
            ];
        },
        [],
    );

    if (isEmpty(errors)) {
        return getUnknownError();
    }

    return errors;
};

export const handleFormException = (exception, formInformation) => {
    const errorCode = exception.errorCode;

    switch (errorCode) {
        case "pardot_submission_error":
            return handlePardotError(formInformation, exception);
        case "gravity_form_field_validation_error":
            return handleGravityFormError(formInformation, exception);
        case "recaptcha_error":
        case "pdf_error":
            return {
                fieldName: "non_field_error",
                type: "custom",
                message: exception.errorMessage,
            };
        case "on24_error":
            return {
                fieldName: "on24_error",
                type: "custom",
                message: exception.errorMessage,
            };
        default:
            return getUnknownError();
    }
};

export const notifyUserOfException = (error, uniqueFormId, setError) => {
    if (isArray(error)) {
        error.forEach((exception) => {
            const name = getFieldName(
                exception.fieldName,
                uniqueFormId,
                exception.field.id,
            );
            setError(name, {
                type: exception.type,
                message: exception.message,
            });
        });
    } else if (
        error.fieldName === "non_field_error" ||
        error.fieldName === "unknown" ||
        error.fieldName === "on24_error"
    ) {
        setError(error.fieldName, {
            type: error.type,
            message: error.message,
        });
    } else {
        const name = getFieldName(
            error.fieldName,
            uniqueFormId,
            error.field.id,
        );
        setError(name, {
            type: error.type,
            message: error.message,
        });
    }
};

export const addErrorSummary = (formFields) => {
    const fieldsWithSummary = [];
    formFields.forEach((field, index) => {
        if (field.type === "html" && index === 0) {
            fieldsWithSummary.push(field);
            fieldsWithSummary.push({ errorSummary: true });
        } else if (index === 0) {
            fieldsWithSummary.push({ errorSummary: true });
            fieldsWithSummary.push(field);
        } else {
            fieldsWithSummary.push(field);
        }
    });
    return fieldsWithSummary;
};
